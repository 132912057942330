import { mapActions, mapGetters } from "vuex"
import ThemisDecision from "@/components/shared/decision"
import {
  TRANSLATION_FILE_UPLOAD_TYPE,
  TRANSLATION_UPLOAD_STATUS,
  TRANSLATION_FILE_SIZE_LIMIT_IN_BYTES,
  OPTION_LIST_TRANSLATION_FILE_UPLOAD_POLL_INTERVAL,
  OPTION_LIST_EXPORT_STATUS
} from "@/constants"

export default {
  name      : "OptionListSidePanel",
  components: {
    ThemisDecision
  },
  data() {
    return {
      showDisableTranslationDialog      : false,
      localOptionList                   : undefined,
      translationFileUploadStatusPolling: null
    }
  },

  computed: {
    ...mapGetters({
      optionLists                         : "optionLists/optionLists",
      isUpdatingMachineTranslateOptionList: "optionLists/isUpdatingMachineTranslateOptionList",
      isMachineTranslateOptionListUpdated : "optionLists/isMachineTranslateOptionListUpdated",
      isAddingOptionListExport            : "optionLists/isAddingOptionListExport",
      isUploadingOptionListItemTranslation: "optionLists/isUploadingOptionListItemTranslation",
      optionListsExport                   : "optionLists/optionListsExport"
    }),
    optionList() {
      return this.optionLists.find(optionList => optionList.id === +this.$route.params.id)
    },
    isTranslationEnabled() {
      return this.optionList.machineTranslateItems
    },
    allowedTranslationDocumentType() {
      return Object.values(TRANSLATION_FILE_UPLOAD_TYPE)
    },
    optionListItemTranslationUploadStatus() {
      return this.optionList?.translationUploadStatus
    },
    isTranslationFileUploadFailed() {
      return this.optionListItemTranslationUploadStatus === TRANSLATION_UPLOAD_STATUS.FAILURE
    },
    isTranslationFileUploadInitiated() {
      return this.optionListItemTranslationUploadStatus === TRANSLATION_UPLOAD_STATUS.INITIATED
    },
    optionListExportStatus() {
      return this.optionListsExport.find(optionListExport =>
        optionListExport.optionListId === this.optionList.id)?.status
    },
    isOptionListExportInitiated() {
      return this.optionListExportStatus === OPTION_LIST_EXPORT_STATUS.INITIATED
    },
    isOptionListExportInProgress() {
      return this.isAddingOptionListExport || this.isOptionListExportInitiated
    },
    isUploadButtonVisible() {
      return !this.isTranslationFileUploadInitiated &&
      !this.optionList.systemDefault &&
      this.optionList.machineTranslateItems
    }
  },
  methods: {
    ...mapActions({
      updateOptionList                   : "optionLists/updateOptionList",
      notify                             : "shared/notify",
      uploadOptionListItemTranslationFile: "optionLists/uploadOptionListItemTranslationFile",
      loadOptionList                     : "optionLists/loadOptionList",
      addOptionListExport                : "optionLists/addOptionListExport"
    }),
    handleTranslationChange() {
      if(this.isTranslationEnabled) {
        this.showDisableTranslationDialog = true
      } else {
        this.updateOptionList({
          id                   : this.localOptionList.id,
          machineTranslateItems: this.localOptionList.machineTranslateItems
        })
      }
    },
    resetTranslationChange() {
      this.showDisableTranslationDialog          = false
      this.localOptionList.machineTranslateItems = this.optionList.machineTranslateItems
    },
    openFileExplorerToSelectDocuments() {
      this.$refs.input_upload_translation_file.click()
    },
    onDocumentSelection(event) {
      const file = event.target.files[0]
      if( file.size < TRANSLATION_FILE_SIZE_LIMIT_IN_BYTES) {
        this.uploadOptionListItemTranslationFile({ optionList: this.optionList, file })
      }
    },
    clearTranslationFileUploadStatusPollingInterval() {
      if(this.translationFileUploadStatusPolling) {
        clearInterval(this.translationFileUploadStatusPolling)
        this.translationFileUploadStatusPolling = null
      }
    }
  },
  watch: {
    optionList: {
      immediate: true,
      handler  : function(newValue) {
        if (!this.localOptionList) {
          this.localOptionList = { ...newValue }
        }
      }
    },
    isMachineTranslateOptionListUpdated: {
      handler: function(value) {
        if (value) {
          if(!this.isTranslationEnabled) {
            this.notify({
              type      : "success",
              text      : "1228",
              parameters: {
                optionListName: this.optionList.name
              }
            })
            this.showDisableTranslationDialog = false
          }
        }
      }
    },
    isUpdatingMachineTranslateOptionList: {
      handler: function(newValue) {
        this.$DECISIONS.DISABLE_OPTION_LIST_TRANSLATION.pActions[0].buttonProps.disabled = newValue
        this.$DECISIONS.DISABLE_OPTION_LIST_TRANSLATION.pActions[1].buttonProps.loading  = newValue
      }
    },
    optionListItemTranslationUploadStatus: {
      immediate: true,
      handler  : function(newValue, oldValue) {
        if(newValue === TRANSLATION_UPLOAD_STATUS.INITIATED) {
          this.translationFileUploadStatusPolling = setInterval(() => {
            this.loadOptionList({ id: this.optionList.id, uploadStatus: newValue })
          }, OPTION_LIST_TRANSLATION_FILE_UPLOAD_POLL_INTERVAL)
        }

        if(newValue === TRANSLATION_UPLOAD_STATUS.SUCCESS || newValue === TRANSLATION_UPLOAD_STATUS.FAILURE) {
          this.clearTranslationFileUploadStatusPollingInterval()
        }
        if(newValue === TRANSLATION_UPLOAD_STATUS.SUCCESS && oldValue === TRANSLATION_UPLOAD_STATUS.INITIATED) {
          this.notify({
            type: "success",
            text: "1059"
          })
        }
      }
    }
  }
}